<template>
  <section class="hot-product m-scroll" ref="scroll">
    <!-- 头部导航 -->
    <HeaderBar title="热销单品">
      <router-link to="/zh/cart" slot="right" class="solt-right">
        <img src="@/assets/index/icon_shop_b@3x.png"  alt="cart" class="right-img">
      </router-link>
    </HeaderBar>

    <van-swipe class="my-swipe" :autoplay="0" indicator-color="white" :stop-propagation="false">
      <van-swipe-item v-for="(item, index) in rankList" :key="index" class="swipe-item">
        <img src="@/assets/goods/top1@2x.png" alt="" class="top-img" v-if="index == 0">
        <img src="@/assets/goods/top2@2x.png" alt="" class="top-img" v-else-if="index == 1">
        <img src="@/assets/goods/top3@2x.png" alt="" class="top-img" v-else>
        <img :src="item.image_url" alt="" class="swipe-img">
        <div class="swipe-info" @click="$router.push(`/zh/goods/detail/${item.id}`)">
            <span>{{item.goods_info.name}}</span>
            <img src="@/assets/goods/link.png" alt="">
        </div>
      </van-swipe-item >
    </van-swipe>

    <!-- 滚动切换 -->
    <van-tabs  scrollspy :offset-top="tabTop" sticky swipeable title-active-color="#ED2A24" title-inactive-color="#000000">
      <van-tab :title="item.title" v-for="(item, index) in classifyList" :key="index">
        <index-cell :title="item.title" value="更多" :to="`/zh/subhot/${item.id}?title=${item.title}`" class="m-index-new" />
        <goods-columns @cartClick="handleCart" :data="goodsList[index]" :columns="1"></goods-columns>
      </van-tab>
    </van-tabs>
    
    <!-- <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading> -->

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MSku         from '@/components/zh/m-sku.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'
import HeaderBar from '@/components/zh/m-header.vue'
import IndexCell    from '@/components/zh/index-cell.vue'

import { getRank, getRankClassify, getRankClassifyGoods } from '@/api/zh/hotsale.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{ GoodsColumns, MSku, MAnimate, CartFlex, HeaderBar, IndexCell },
  data(){
    return {
      tab:0,          // tabs选中的面板
      recomnd:4,
      loading:false,
      skuModal:false,
      tabTop:44,
      dotAnimate:false,
      start:false,
      start_dom:null,
      cartNum:0,
      scrollTop:false,
      swipeBg1: '//img.alicdn.com/imgextra/i3/730320029/O1CN01EyMkhZ1C5K5sFdlpj_!!0-saturn_solar.jpg_220x220.jpg_.webp',
      swipeBg2: '//img.alicdn.com/imgextra/i3/1253260119/O1CN01XiAXfW1CkXk4EQ2qe_!!0-saturn_solar.jpg_220x220.jpg_.webp',
      rankList: [],
      goodsList: [],
      classifyList: [],
      goodsId: ''
    }
  },

  mounted(){
    let clientWidth = document.body.clientWidth
    this.tabTop = 44/375*clientWidth

    this.getRankHandle()
    this.getRankClassifyHandle()
  },

  methods:{
    // 轮播图切换时间
    handleChange(index){
        let len = this.swipeImgs.length

        if(this.swipeImgs[index-1]){
            this.swipeBg1 = this.swipeImgs[index-1]
        }else{
            this.swipeBg1 = this.swipeImgs[len-1]
        }
        if(this.swipeImgs[index+1]){
            this.swipeBg2 = this.swipeImgs[index+1]
        }else{
            this.swipeBg2 = this.swipeImgs[0]
        }

    },

    handleClick(e){
        console.log(e)
    },

    // 切换 tab
    handleTab(tab){
      let left = 6.56667 + tab  * 21.66667
      this.$refs.tabActive.style.left = left + 'vw'
      this.tab = tab
    },
    // 点击筛选 tab
    handleFilter(){
      this.filter = true
    },
    // 点击月份筛选
    handleMonth(month){
      this.month = month
      let timer = setTimeout( () => {
        this.filter = false
        clearTimeout(timer)
      }, 300)
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getRankHandle() {
      // 获取热销排名
      getRank(3).then(res => {
        this.rankList = res.data.slice(0, 3)
      })
    },
    getRankClassifyHandle() {
      // 获取自营专区分类
      getRankClassify().then(res => {
        if(res) {
          if (this.$store.state.isMiniProgram) {
              this.classifyList = res.data.filter(item => {
                    return  item.id != 34 && item.id != 35 && item.id != 36 && item.id != 37 && item.id != 38 && item.id != 45 && item.id != 46 && item.id != 49 && item.id != 50 && item.id != 53 && item.id != 55
              });
          }else{
              this.classifyList = res.data
          }
          this.classifyList.forEach((el, index) => {
            this.getRankClassifyGoodsHandle(el.id, index)
          })
        }
      })
    },
    getRankClassifyGoodsHandle(id, index) {
      // 获取分类下的商品
      this.goodsList[index] = []
      getRankClassifyGoods(id,{column_id: 3, page: 1}).then(res => {
        if(res) {
          this.$set(this.goodsList, index, res.data.data)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.hot-product {
    width: 100%;
    height: 100%;
    padding-top: 44px;
    background-color: #F5F9FF;
    overflow-x: hidden;
    overflow-y: auto;
    .my-swipe {
        position: relative;
        z-index: 9;
        width: 100%;
        height: 350px;
        margin-top: 26px;
        margin-bottom:26px;
        overflow: initial !important;
        .top-img{width:60px;height:64px;position: absolute;top:-16px;left:36px;}
        .swipe-item {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .swipe-img {
            width: 343px;
            height: 350px;
            border-radius: 5px;
        }
        .swipe-info{position: absolute;bottom:0;left:0;width:calc(100% - 32px);margin:0 16px;border-radius: 5px;
        padding:50px 15px 20px 15px;background: linear-gradient(180deg, rgba(70, 50, 11, 0) 0%, #000000cb 100%);color:#fff;display: flex;
            span{
                display: block;
                flex: 1;
                font-size: 18px;
                
                font-weight: 500;
                color: #FFFFFF;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            img{width:32px;height:22px;margin:15px 0 15px 10px}
        }
    }
    /deep/.van-tabs__content{margin-top:16px;}
    .sale-tip{width:100%;height:40px;line-height: 40px;margin:10px 0;padding-left:16px;font-weight: 500;}
    .m-index-new {
        padding-top: 17px;
        padding-bottom: 12px;
    }
    .m-index-margin {
        margin: 20px 0 12px 0;
    }
    .right-img {
      width:22px;
      height:22px;
      margin:6px;
    }
}
</style>